$component-name: 'l-primary';

.#{$component-name} {
    grid-area: primary;
    padding-right: 7%;
    grid-area: tertiary;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    @include breakpoint(medium-only) {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

}

.side-elements{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 2%;
    height: 90%;
    margin: 0px;
    padding: 0px;
    img {
        position: relative;
        height: 708px;
    }


}
// h6 {
//     border-right: .05em solid;
//     animation: caret 1s steps(1) infinite;
//   }
  
//   @keyframes caret {
//     50% {
//       border-color: transparent;
//     }
//   }
h6{
    font-family: "Exo 2";
    color: #fff;
    background-color: #ff3c00;
    font-size: 3.625rem;
    font-weight: 600;
    padding-bottom: 0.4375rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    margin: 0px;
    margin-bottom: 0.625rem;
}


.css-typing h6 {

    overflow: hidden;
    height: 73px;
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing h6:nth-child(1) {
    width: 6.3em;
    -webkit-animation: type 3s steps(40, end);
    animation: type 3s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    
  }
  
  .css-typing h6:nth-child(2) {
    width: 5.5em;
    opacity: 0;
    -webkit-animation: type2 3s steps(40, end);
    animation: type2 3s steps(40, end);
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    
  }


  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
      
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
     
    }
  }

  

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }