$component-name: 'c-bank-games';
$align-component-name: 'align-' + $component-name;

@debug $align-component-name;
@debug $component-name;

.#{$component-name} {
    &__title{
        color: $color-secondary;
        background-color: #272932;
        padding: rem(8) rem(14);
        font-size: rem(24);
        font-weight: 500;
        display: inline-block;

    }
    &__cidade-alta{
        float: left;
        img{
            width: 90px;
        }

    }
    &__mapa-fortnite{
        margin-top: 13px;
        float: right;
        padding-bottom: 100px;
        img{
            width: 250px;
        }
    }
    
}

.#{$align-component-name}{
    float: right;
    width: 397px;

}

