$component-name: 'c-rotating-text';

.#{$component-name} {
    &__wrap{
        float: left;
        width: auto;

    }
    &__item{
        font-family: $font-family-primary;
        color: $color-primary;
        background-color: $color-secondary;
        font-size: rem(58);
        font-weight: 600;
        padding-bottom: rem(7);
        padding-left: rem(15);
        padding-right: rem(15);
        margin: 0px;
        margin-bottom: rem(10);
        display: inline-block;
        

    }

}