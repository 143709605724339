// Hexagon
$sqrt3: 1.7320508075688772;
$edgeSize: 100px;
$hexWidth: $edgeSize * 2;
$hexHeight: $sqrt3 * $edgeSize;

// Display
$borderHover: 15px;
$soft-white: #ff3c00;

// Easing
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);


.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
}
/* End of container */

// Contains 2 hexagons (.hex)
.button {
  cursor: pointer;
  width: $hexWidth;
  height: $hexHeight;
  float: left;
  
  &:last-child {
    margin-right: 0;
  }

  // Hover state

}

.button-hover {
    .hex:last-child {
      opacity: 1;
      transform: scale(1);
    }
    .hex:first-child {
      opacity: 1;
      
      
      div:before, div:after {
      }
      
  
    }
}
// Contains 3 div that makes the hexagon
.hex {
  position: absolute;
  top: 0;
  left: $edgeSize/2;
  width: $edgeSize;
  height: $hexHeight;
  opacity: 1;
  
  // Inset
  &:first-child {
    transform: scale(0.92);
    transition: all 0.3s $ease-out-quart;
    z-index: 0;
  }
  
  // Outset
  &:last-child {
    transition: all 0.3s $ease-out-expo;
    z-index: 1;
    transform: scale(0.91);
    
  }
  
  div {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: $edgeSize;
    height: $hexHeight;
    
    &:before, &:after {
      content: ' ';
      position: absolute;
      background: $soft-white;
      width: 100%;
      height: 1.5px;
      
      transition: height 0.3s $ease-out-quart;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    &:nth-child(1) {
      transform: rotate(0deg);
    }
    &:nth-child(2) {
      transform: rotate(60deg);
    }
    &:nth-child(3) {
      transform: rotate(120deg);
    }
    
    transform-origin: center center;
  }
}

.hex-cell {
    overflow: hidden;
    grid-column-end: span 2;
    margin: calc(-1 * var(--hl)) 0;
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    width: 179px;
    height: 171px;

  
  img {
    --hl: 0;
    width: 100%; height: 100%;
    object-fit: cover;
    transform: scale(calc(1 + .2*var(--hl)));
    filter: brightness(calc(.6*(1 + var(--hl))));
    transition: .7s;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
    
    &:hover { --hl: 0.5;
        filter: none;
        transition: 1s;
        -webkit-filter: grayscale(0%);  
    }
  }
}
.item-gamers{
    position: absolute;
    top: 0;
    &:nth-child(1){
        left: 6px;
        top: 9px;
    }
    &:nth-child(2){
        right: 0;
        top: 98px;
    }
    &:nth-child(3){
        left: 0;
        top: 189px;
    }
    &:nth-child(4){
        right: 0;
        top: 278px;
    }
    &:nth-child(5){
        left: 0;
        top: 369px;
    }
    &:nth-child(6){
        right: 0;
        top: 459px;
    }
  span{
    color: #fff;
    font-size: 11px;
    font-family: $font-family-primary;
    text-align: center;
    width: 100%;
    font-style: italic;
    display: block;
    position: absolute;
    bottom: -9px;
    z-index: 1;
  }
}
