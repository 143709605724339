@charset "utf-8";

// ==========================================================================
// Abstract
// ==========================================================================

@import "abstract/unit";
@import "abstract/variables";
@import "abstract/breakpoint";
@import "abstract/z-index";

@import "../node_modules/autocompleter/autocomplete.min.css";

:root {
  @each $name, $color in $webfatorial-colors {
    --color-#{$name}: #{$color};
  }

  @each $name, $color in $dpw-colors {
    --color-#{$name}: #{$color};
  }

  @each $name, $color in $social-media-colors {
    --color-#{$name}: #{$color};
  }
}

// ==========================================================================
// Vendor
// ==========================================================================

@import "vendor/normalize";

// ==========================================================================
// Base
// ==========================================================================

@import "base/elements";
@import "base/generics";
@import "base/a11y";

// ==========================================================================
// Layout
// ==========================================================================

// @import "layout/l-";

@import "layout/preloader";
@import "layout/l-primary";
@import "layout/l-secondary";
@import "layout/l-tertiary";

// ==========================================================================
// Component
// ==========================================================================
// ---> Layout Primary
@import "component/c-bank-games";
@import "component/c-content-form";
@import "component/c-logo";
@import "component/c-parceiros";
@import "component/c-rotating-text";
@import "component/c-parceiros-hexagono";

// ==========================================================================
// State
// ==========================================================================

// @import "state/is-";

// ==========================================================================
// Page
// ==========================================================================

// @import "page/";

// ==========================================================================
// Theme
// ==========================================================================

// @import "theme/t-";

// ==========================================================================
// Shame (hacks & dishonorable stuff)
// ==========================================================================
 
input[type=text]:focus, textarea:focus {
  box-shadow: 0 0 11px #ff3b00;
}
@include breakpoint(medium-only) {
  html,
  body {
    overflow-x: hidden;
  }
  body {
    position: relative;
  }
  body {
    padding: 0px;
  }
  .l-primary {
    width: 100vw;
    height: 100vh;
    position: relative;
    padding: $global-module-size;
    // background-image: url("../img/bg-mobile.jpg");
    background-size: 112%;
    background-position: center;

    .c-logo{
      position: absolute;
    }
    .content-influencers {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      padding-bottom: 23%;
      img{
        height: 61vh;
        max-width: fit-content !important;
        margin-top: -74px;
        
      }

      .c-rotating-text {
        margin-left: 122px;

        h1 {
          font-size: 20px;
          margin-bottom: 2px;
          padding: 0px 8px 2px 7px;
        }
      }
    }
  }
  .l-secondary {
    width: 100vw;
    height: 100vh;
    padding: $global-module-size;
    padding-top: 30px;
    position: relative;
    background-color: #000000e8;
    .c-form__title {
      color: white;
      text-align: center;
    }
  }
  .l-tertiary {
    width: 100vw;
    height: 100vh;
    padding: $global-module-size;
    position: relative;
    background-image: url("../img/bg_mobile_2.png");
    background-size: 112%;
    background-position: center;
    justify-content: center;
    padding-bottom: 33%;
    .c-parceiros{
      h2{
        text-align: center;
        margin-bottom: 30px;
        font-size: 19px;
      }
 
    }
    .c-parceiros__wrap{
      grid-template-areas:
      "item-1 item-2 item-3"
      "item-4 item-5 item-6"
      "item-7 item-8 item-9"
      "item-10 item-11 item-12"
      "item-13 item-14 item-15";
      justify-items: center;
      
    }
    .c-parceiros__item{
      margin-bottom: 25px;
    }
    .c-parceiros__item-off{
      width: 90px;
    }
    .c-parceiros__copyright{
      text-align: center;
      display: block;
      margin-top: 21px;
      color: #fff;
      font-size: 12px;
    }
  }
  .l-fourth {
    width: 100vw;
    height: 100vh;
    padding: $global-module-size;
    position: relative;
    background-image: url("../img/bg_mobile_2.png");
    background-size: 112%;
    background-position: center;
    justify-content: center;
    padding-bottom: 33%;
    .c-bank-games{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }

  @include breakpoint(large-only) {
    .bg_video-mobile{
      display: block !important;
    }
    .content-button-mobile {
      position: fixed;
      bottom: 0px;
      left: 0;
      width: 100%;
    }

    .start-1 {
      text-align: center;
      color: black;
      background-color: #ff8c0d;
      text-decoration: none;
      width: 100%;
      float: left;
      padding: 4px 0px 11px 0px;
      font-size: 30px;
      font-weight: 600;
    }
    .start-2 {
      display: none;
      position: relative;
      .prev{
        color: white;
        font-size: 26px;
        background-color: #ff3801;
        top: 0;
        padding: 7px 19px;
        padding-bottom: 13px;
        border-radius: 0px;
        margin-top: 0px;
      }
    }
  }
  .up {

  }
  .seja-primeiro{
    color: #bdbdbd;
    font-size: 16px;
    text-align: center;
    margin-top: -30px;
  }
  .modal-form {
    opacity: 0;
    animation: fadein 0.5s;
    transition: 1s;
    position: fixed;
    overflow: hidden;
    z-index: 2;
    top: 0;
    display: none;
  }
  .active-modal {
    display: flex;

    opacity: 1;
  }
  .opacity-zero {
    opacity: 0;
  }
  .content-button-mobile-fixed {
    position: fixed;
    bottom: 0px;
    z-index: 1;
  }
  .align-c-content-form {
    margin-top: -40%;
    .c-content-form {
      padding: 35px 19px;
    }
    .c-form {
      grid-template-columns: auto;
      grid-template-areas:
        "c-name"
        "c-mail"
        "c-phone"
        "c-age"
        "c-favorite-game"
        "c-send"
        "c-terms";
      input {
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
      }
      .c-form__item {
        border-radius: 0px;
        font-size: 16px;
        padding: 10px 16px;
      }
    }
  }
}

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 5px;
  width: 25px;
  margin: 0 2px;
  background-color: #717171;
  display: inline-block;
  transition: background-color 0.6s ease;
  border: none;
}

.active,
.dot:hover {
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.navT {
  -webkit-transition: all 0.25s;
  z-index: 111;
  cursor: pointer;
  width: 100%;
  height: 65px;
  right: 10%;
  top: 0;
  position: absolute;

  .icon {
    -webkit-transition: all 0.25s;
    position: absolute;
    width: 30px;
    height: 1px;
    right: 0px;
    top: 30px;
    background-color: white;

    &:before,
    &:after {
      -webkit-transition: all 0.25s;
      width: 30px;
      height: 1px;
      left: 0;
      background: white;
      position: absolute;
      content: "";
    }
    &:before {
      top: -10px;
    }
    &:after {
      top: 10px;
    }
  }
  &:hover {
    .icon {
      &:before {
        top: -12px;
      }
      &:after {
        top: 12px;
      }
    }
  }
  &.active {
    .icon {
      -webkit-transform: rotate(180deg);
      background-color: transparent;
      right: 0px;

      &:before,
      &:after {
        top: 0;
      }
      &:before {
        -webkit-transform: rotate(45deg);
      }
      &:after {
        -webkit-transform: rotate(-45deg);
      }
    }
  }
}
.mobile-fixed {
  position: fixed;
  transition: 0.5s;
  top: -70px;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #15171cf7;
  box-shadow: 2px 6px 10px #00000094;
  img {
    width: 110px;
    margin: 13px 0px 9px 18px;
  }
  &.active {
    top: 0;
  }
}
.blur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.scroll-stop {
  position: fixed;
}
.mobile {
  display: none;
  @include breakpoint(medium-only) {
    display: flex;
  }
}
