$component-name: 'l-tertiary';

.#{$component-name} {

    grid-area: tertiary;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-right: 3%;
    padding-left: 6%;
    @include breakpoint(medium-only) {
        height: calc(100vh - (2 * #{$global-module-size}) );
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
}