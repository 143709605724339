$component-name: "c-content-form";
$align-component-name: "align-" + $component-name;
$component-name2: "c-form";
.success{
  display: none;
}
.#{$align-component-name} {
  width: 100%;
}
.#{$component-name} {
  ul{
    list-style: none;
    color: white;
    border: 1px solid #ff774c;
    padding: 10px;
    font-size: 12px;
  }
  #success-desktop,#success-mobile{
    h1{
      font-size: 1.5rem;
      font-weight: 500;
      color: #ff774c;
      margin-bottom: 0px;
    }
    h2{
      color: #b5b4b4;
      font-size: 1.3rem;
      font-weight: 300;
      margin-top: 0px;
    }
    p{
      color: #b5b4b4;
      font-family: $font-family-primary;
      font-size: 1rem;
      line-height: 20px;
    }
    b{
      color: #ff774c;
      font-weight: normal;

    }
    .qr-code{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
      &__item{
        display: flex;
        flex-direction: column;
        img{
          width: 130px;
        }
      }
      &__item:first-child{
        border-right: 1px solid #f37c58;
        padding-right: 30px;
      }
      &__item:last-child{
        padding-left: 30px;
      }
      &__info:last-child{
        img{
          width: 13px;
          height: 16px;
          margin-right: 4px;
          margin-top: 3px;
        }
      }
      &__info{
        display: flex;
        padding: 10px 0px;
        justify-content: center;
        text-decoration: none;
        img{
          width: 21px;
          margin-right: 4px;
          margin-top: 3px;
        }

        span{
          color: #b5b4b4;
        }
      }
    }

    
  }
  background-color: #2e2f3891;
  padding: $global-module-size;
  border-radius: $global-radius;
  border: 1px solid $color-secondary;
  box-shadow: -1px 0px 32px 3px #ff3c004f;
  input{
    caret-color: #ff774c;
    color: #ff774c;;
    text-transform: uppercase;
  }
}

.#{$component-name2} {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "c-title c-title "
  "c-name c-name"
  "nome_completo-error nome_completo-error"
  "c-mail c-mail"
  "email-error email-error"
  "c-phone c-age"
  "cpf-error data_de_nascimento-error"
  "c-favorite-game c-favorite-game"
  "game-desktop-error game-desktop-error"
  "c-send ."
  "c-terms c-terms";


  .form-buttom-carrousel {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
  }
  #nome_completo-error{
    grid-area: nome_completo-error;
    color: #ff774c;
    font-size: 12px;
    font-family: 'Exo 2';
    margin-top: -6px;
    margin-bottom: 7px;
    padding: 0px 6px;
  }
  #email-error{
    grid-area: email-error;
    color: #ff774c;
    font-size: 12px;
    font-family: 'Exo 2';
    margin-top: -6px;
    margin-bottom: 7px;
    padding: 0px 6px;
  }
  #cpf-error{
    grid-area: cpf-error;
    color: #ff774c;
    font-size: 12px;
    font-family: 'Exo 2';
    margin-top: -6px;
    margin-bottom: 7px;
    padding: 0px 6px;
  }
  #data_de_nascimento-error{
    grid-area: data_de_nascimento-error;
    color: #ff774c;
    font-size: 12px;
    font-family: 'Exo 2';
    margin-top: -6px;
    margin-bottom: 7px;
    padding: 0px 6px;
  }
  #game-desktop-error{
    grid-area: game-desktop-error;
    color: #ff774c;
    font-size: 12px;
    font-family: 'Exo 2';
    margin-top: -6px;
    margin-bottom: 7px;
    padding: 0px 6px;
  }

  &__title {
    color: #b5b4b4;
    grid-area: c-title;
    b{
      color: #ff774c;
      font-weight: normal;
    }
  }
  &__item {
    border: 1px solid #9f9fa2;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 8px;
    background-color: #32353e;
    font-size: 12px;
    color: #9f9fa2;
  }
  &__name {
    grid-area: c-name;
  }
  &__last-name {
    grid-area: c-last-name;
    margin-left: 5px;
  }
  &__mail {
    grid-area: c-mail;
  }
  &__phone {
    grid-area: c-phone;
    margin-right: 5px;
  }
  &__age {
    grid-area: c-age;
    margin-left: 5px;
  }
  &__favorite-game {
    grid-area: c-favorite-game;
  }
  &__send {
    grid-area: c-send;
    background-color: #ff774c;
    font-weight: 700;
    font-family: $font-family-primary;
    color: $color-quaternary !important;
    border: none;
    padding: rem(9);
    border-radius: rem(27);
    font-size: rem(15);
    transition: 1s;
    &:hover{
      transition: 1s;
      background-color: #fff;
    }
  }
  &__terms {
    grid-area: c-terms;
    font-size: rem(10);
    color: #b5b4b4;
    font-family: $font-family-primary;
    margin-top: 10px;
  }
}


.autocomplete {
  border-radius: 5px;
  font-family: "Exo 2";
  font-size: 14px;
  .selected{
    background-color: #ff774c !important;
    padding: 3px 10px;
    font-family: 'Exo 2';
    font-size: 13px;
    border: none;

  }
  div{
    padding: 3px 10px;
    font-family: 'Exo 2';
    font-size: 13px;
    border: none;
  }
}