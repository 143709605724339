html {
  box-sizing: border-box;
}

// @see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// @see https://www.nicchan.me/blog/tips-for-focus-styles/#consider-adding-a-little-bit-of-breathing-room
*,
*::before,
*::after {
  box-sizing: inherit;
  outline-offset: 0.25rem;
}

// @see: https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
@media (prefers-reduced-motion: reduce), (update: slow) {
  *,
  ::before,
  ::after {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

// @see https://www.zachleat.com/web/fluid-images/
img,
iframe,
object,
embed,
video {
  max-width: 100%;
}

img[width],
iframe[width],
object[width],
embed[width],
video[width] {
  width: auto; /* Defer to max-width */
}

img[width][height],
iframe[width][height],
object[width][height],
embed[width][height],
video[width][height] {
  height: auto; /* Preserve aspect ratio */
}

/* Let SVG scale without boundaries */
img[src$=".svg"] {
  height: auto;
  max-width: none;
  width: 100%;
}

label {
  cursor: pointer;
}

sup,
sub {
  font-size: 50%;
}

// @see https://matthiasott.com/notes/focus-visible-is-here
:focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

// ==========================================================================
// Project specifics
// ==========================================================================

// @see https://twitter.com/argyleink/status/1413850241477005312
:root {
  --brand: #157bad;
  accent-color: var(--brand);
}

html {
  caret-color: var(--brand);
}

body {
  background-image: url("../img/bg.png");
  background-size: cover;
  font-family: $font-family-primary;
  font-size: $global-font-size;
  width: 100vw;
  padding: $global-module-size;
  @include breakpoint(fullhd-up) {
    width: 1920px;
    margin: 0 auto;
    display: block;
  }
}
.wrap-mobile {
  display: block;
  @include breakpoint(medium-up) {
    display: none;
  }
}
.wrap {
  display: none;
  @include breakpoint(medium-up) {
    display: flex;
    height: calc(100vh - (2 * #{$global-module-size}));
    justify-content: space-between;
  }
}

:focus-visible {
  outline-color: var(--brand);
}

::selection {
  background-color: var(--brand);
}

::marker {
  color: var(--brand);
}

::-webkit-calendar-picker-indicator {
  color: var(--brand);
}

::-webkit-clear-button {
  color: var(--brand);
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  color: var(--brand);
}

// input::placeholder {}
// textarea::placeholder {}
// input::-webkit-input-placeholder {}
// textarea::-webkit-input-placeholder {}
// input::-moz-placeholder {}
// textarea::-moz-placeholder {}
// input:-ms-input-placeholder {}
// textarea:-ms-input-placeholder {}
