$component-name: 'c-parceiros';

.#{$component-name} {
    &__title{
        color: $color-primary;
        font-size: rem(15);
        font-weight: 500;
        font-style: italic;
    }
    &__wrap{
        display: grid;
        grid-template-areas:
        "item-1 item-2 item-3 item-4"
        "item-5 item-6 item-7 item-8"
        "item-9 item-10 item-11 item-12"
        "item-13 item-14 item-15 item-16"

    }
    &__item{
        grid-area: parceiroitem;
        display: flex;
        align-items: center;
        max-width: rem(75);
        margin: 0px rem(15);
        margin-bottom: 10px;

    }
    &__item-off{
        background: #00000057;
        height: rem(48);
        position: relative;
        margin: 0px 8px;
        margin-bottom: 11px;
        border-radius: 3px;
    }
    &__count{
        right: 6px;
        top: 5px;
        position: absolute;
        font-size: 8px;
        color: $color-secondary;
    }
    &__icon{
        display: block;
        width: 14px;
        margin: 0 auto;
        margin-top: 13px;
    }
    &__item-1{
        grid-area: item-1;

    }
    &__item-2{
        grid-area: item-2;

    }
    &__item-3{
        grid-area: item-3;

    }
    &__item-1{
        grid-area: item-1;

    }
    &__item-4{
        grid-area: item-4;

    }
    &__item-5{
        grid-area: item-5;

    }
    &__item-6{
        grid-area: item-6;

    }
    &__item-7{
        grid-area: item-7;

    }
    &__item-8{
        grid-area: item-8;

    }
    &__item-9{
        grid-area: item-9;

    }
    &__item-10{
        grid-area: item-10;

    }
    &__item-11{
        grid-area: item-11;

    }
    &__item-12{
        grid-area: item-12;

    }
    &__item-13{
        grid-area: item-13;

    }
    &__item-14{
        grid-area: item-14;

    }
    &__item-15{
        grid-area: item-15;

    }
    &__item-16{
        grid-area: item-16;

    }
    &__copyright{
        color: $color-quinary;
        font-size: rem(10);
    }

}
