$component-name: "l-secondary";

.#{$component-name} {
  grid-area: secondary;
  align-items: center;
  display: flex;
  padding-right: 3%;
  
  @include breakpoint(medium-only) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  img {
    max-height: 90vh;
  }
  .aling-secondary{
    width: 332px;
    position: relative;
    top: 0;
    display: flex;
    height: 650px;
    background-image: url('../img/influencers-bg-3.png');
    background-size: 95%;
    background-position-y: -11%;
    background-position-x: center;
  }
}
